import React from "react"
import { Button, Form } from "react-bootstrap"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Contact() {
  const [state, setState] = React.useState({})
  const [validated, setValidated] = React.useState(false)

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    const form = e.target
    setValidated(true)
    e.preventDefault()
    if (form.checkValidity() === false) {
      e.stopPropagation()
    } else {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...state,
        }),
      })
        .then(() => resetForm(form))
        .catch(error => alert(error))
    }
  }

  const resetForm = ref => {
    alert("Gracias! Pronto nos contactaremos con usted.")
    setValidated(false)
    ref.reset()
  }

  return (
    <Form
      noValidate
      validated={validated}
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <Form.Group controlId="formName">
        <Form.Control
          required
          placeholder="Nombre completo"
          name="name"
          onChange={handleChange}
        />
        <Form.Control.Feedback type="invalid">
          Por favor, ingrese su nombre.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formGridEmail">
        <Form.Control
          required
          type="email"
          placeholder="Correo Electrónico"
          name="email"
          onChange={handleChange}
        />
        <Form.Control.Feedback type="invalid">
          Por favor, ingrese su correo.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formOrganization">
        <Form.Control
          placeholder="Organización (opcional)"
          name="organization"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formWebsite">
        <Form.Control
          placeholder="Página web (opcional)"
          name="website"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formMessage">
        <Form.Control
          required
          as="textarea"
          rows={5}
          placeholder="Mensaje"
          name="message"
          onChange={handleChange}
        />
        <Form.Control.Feedback type="invalid">
            Por favor, escriba un mensaje.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formContentOwner" className="text-left">
        <Form.Check
          type="checkbox"
          label="Soy dueño de contenido"
          name="contentowner"
          onChange={handleChange}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Enviar
      </Button>
    </Form>
  )
}
