import React from "react"
import { Container, Row, Col, Button, Image } from "react-bootstrap"
import Layout from "../components/layout"
import FluidImage from "../components/image"
import FixedImage from "../components/fixedImage"
import SEO from "../components/seo"
import ContactForm from "../components/contact"

const IndexPage = () => (
  <Layout>
    <SEO
      title="amwatching - Join the fun!"
      ignoreTitleTemplate={true}
      keywords={[
        `VOD`,
        `OTT Platform`,
        `Video-on-demand`,
        `OTT Platforms`,
        `VoD Website`,
        `video on demand`,
        `OTT Software`,
        `VOD Software`,
      ]}
    />
    <Container fluid className="masthead">
      <Container>
        <Row>
          <Col>
            <h1 className="masthead-heading">
              La plataforma que llegó para revolucionar la forma de ver
              contenido.
            </h1>
            <h2 className="masthead-subheading">
              Promociona y monetiza fácilmente tu contenido directamente con la
              audiencia, combinando diferentes modelos de negocios
            </h2>
            <Button
              variant="primary"
              className="text-uppercase"
              href="#contact"
            >
              quiero saber más
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
    <Container className="ui">
      <Row>
        <Col>
          <FluidImage filename="ui_amw.png" />
        </Col>
      </Row>
    </Container>
    <Container className="features-icons text-center">
      <Row>
        <Col md={4}>
          <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <FixedImage filename="anunciantes.png" />
            <h3>Anunciantes</h3>
            <p className="lead mb-0">
              Impacta a tu audiencia de una forma única, con un claro control de
              presupuesto.
            </p>
          </div>
        </Col>
        <Col md={4}>
          <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <FixedImage filename="audiencia.png" />
            <h3>Audiencia</h3>
            <p className="lead mb-0">
              Nuevas experiencias de consumo, contenido exclusivo e interacción
              social sin precedentes.
            </p>
          </div>
        </Col>
        <Col md={4}>
          <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <FixedImage filename="content_owner.png" />
            <h3>Dueños de contenido</h3>
            <p className="lead mb-0">
              Promociona y monetiza fácilmente tu contenido directamente con la
              audiencia, combinando diferentes modelos de negocio.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
    <Container className="about bg-primary text-white p-5">
      <Row>
        <Col md={6}>
          <FluidImage filename="smart_celu_amw.png" />
        </Col>
        <Col className="my-auto text-center text-md-left" md={6}>
          <h3>
            Series, documentales, películas, shows en vivo, recitales, obras de
            teatro y mucho más.
          </h3>
          <br />
          <ul>
            <li>Desde cualquier dispositivo, en cualquier lugar</li>
            <li>Combinando diferentes modelos de monetización</li>
            <li>Integración con redes sociales</li>
            <li>Integración con plataforma de Ads</li>
          </ul>
        </Col>
      </Row>
    </Container>
    <Container className="features-icons text-center">
      <Row>
        <Col md={3}>
          <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <FixedImage filename="monetizacion.png" />
            <h3>100% Monetizable</h3>
            <p className="lead mb-0">
              Crea un modelo de negocio D2C combinando diferentes modelos de
              monetización: SVOD, TVOD, AVOD.
            </p>
          </div>
        </Col>
        <Col md={3}>
          <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <FixedImage filename="reportes.png" />
            <h3>Reportes</h3>
            <p className="lead mb-0">
              Recibe métricas personalizadas para segmentar audiencias y
              analizar tendencias de consumo.
            </p>
          </div>
        </Col>
        <Col md={3}>
          <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <FixedImage filename="seguridad.png" />
            <h3>Seguridad</h3>
            <p className="lead mb-0">
              Reproducción segura y encriptada de alta velocidad, sin largas
              esperas ni cargas tediosas.
            </p>
          </div>
        </Col>
        <Col md={3}>
          <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <FixedImage filename="innovacion.png" />
            <h3>Innovación Tecnológica</h3>
            <p className="lead mb-0">
              Siempre a la vanguardia de las últimas tecnologías.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
    <Container fluid className="contact-form mb-5" id="contact">
      <Container className="form bg-light text-white text-center">
        <Row>
          <Col>
            <h3>Quieres ser parte?</h3>
            <p className="lead mb-5">
              Envía tus datos y nos pondremos en contacto.
            </p>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </Container>
  </Layout>
)

export default IndexPage
