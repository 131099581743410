import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

const Footer = () => (
  <footer className="footer bg-black p-5 text-white">
    <Container>
      <Row>
        <Col md={4} className="text-center text-lg-left mb-4 mb-lg-0">
          <span className="brand font-weight-bold">amw.tv</span>
        </Col>
        <Col md={4} className="text-center text-lg-left my-auto">
          <p className="text-muted small mb-4 mb-lg-0">
            &copy; amwatching {new Date().getFullYear()}. Todos los derechos
            reservados.
          </p>
        </Col>
        <Col md={4} className="text-center text-lg-right">
          <ul className="list-inline mb-0">
            <li className="list-inline-item mr-2">Síguenos</li>
            <li className="list-inline-item mr-3">
              <a href="https://www.facebook.com/amwatching.tv">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
            <li className="list-inline-item mr-3">
              <a href="https://www.linkedin.com/company/amwatching/">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.instagram.com/amwatching.tv">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
