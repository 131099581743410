import React from "react"
import { Navbar, Container } from "react-bootstrap"

function Navibar() {
  return (
    <Navbar expand="md" className="navibar shadow-lg" bg="black" variant="dark">
      <Container>
        <Navbar.Brand href="/" className="mx-auto font-weight-bold">
          amw.tv
        </Navbar.Brand>
      </Container>
    </Navbar>
  )
}

export default Navibar
